define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/apply-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#stem-category-insert categories="159"}}
    <a
      href={{i18n "components.project_interests_button.url"}}
      class="btn-default btn btn-icon-text"
    >
      {{d-icon "check-circle"}}
      {{i18n "components.project_interests_button.label"}}
    </a>
  {{/stem-category-insert}}
  */
  {
    "id": "bA/MD0tQ",
    "block": "[[[6,[39,0],null,[[\"categories\"],[\"159\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[15,6,[28,[37,2],[\"components.project_interests_button.url\"],null]],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"check-circle\"],null]],[1,\"\\n    \"],[1,[28,[35,2],[\"components.project_interests_button.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"stem-category-insert\",\"a\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/apply-button.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-module-items", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each listSet as |item|}}
    <li>
      {{item.content}}
    </li>
  {{/each}}
  */
  {
    "id": "keQZfqkR",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"listSet\"]]],null]],null],null,[[[1,\"  \"],[10,\"li\"],[12],[1,\"\\n    \"],[1,[30,1,[\"content\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `listSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-module-items.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.listSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"item\"],false,[\"each\",\"-track-array\",\"li\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-module-items.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-list-area/my-image", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.enable_image}}
      {{#stem-category-insert categories="453"}}
          <div class="my-custom-image-container">
          {{stem-image
              imgPath="illustrations/4.png"
          }}    
          </div>
      {{/stem-category-insert}}
      {{#stem-category-insert categories="455"}}
          <div class="my-custom-image-container">
          {{stem-image
              imgPath="illustrations/4.png"
          }}    
          </div>
      {{/stem-category-insert}}
  {{/if}}
  
  */
  {
    "id": "1ByWdl7h",
    "block": "[[[41,[30,0,[\"siteSettings\",\"enable_image\"]],[[[6,[39,1],null,[[\"categories\"],[\"453\"]],[[\"default\"],[[[[1,\"        \"],[10,0],[14,0,\"my-custom-image-container\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"imgPath\"],[\"illustrations/4.png\"]]]],[1,\"    \\n        \"],[13],[1,\"\\n\"]],[]]]]],[6,[39,1],null,[[\"categories\"],[\"455\"]],[[\"default\"],[[[[1,\"        \"],[10,0],[14,0,\"my-custom-image-container\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"imgPath\"],[\"illustrations/4.png\"]]]],[1,\"    \\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-list-area/my-image.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"stem-category-insert\",\"div\",\"stem-image\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-list-area/my-image.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
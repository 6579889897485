define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-tooltip", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  <span class="stem-tooltip-text">
    {{text}}
  </span>
  */
  {
    "id": "UtrO55Th",
    "block": "[[[18,2,null],[1,\"\\n\"],[10,1],[14,0,\"stem-tooltip-text\"],[12],[1,\"\\n  \"],[41,[28,[32,0],[\"text\"],null],[[[8,[39,3],null,null,null]],[]],[[[44,[[28,[37,5],null,[[\"text\"],[[28,[32,1],[\"text\"],null]]]]],[[[1,[52,[30,1,[\"text\"]],[28,[30,1,[\"text\"]],null,null],[28,[32,2],[[30,0],\"text\",\"[\\\"The `text` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-tooltip.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.text}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"],[13]],[\"maybeHelpers\",\"&default\"],false,[\"yield\",\"span\",\"if\",\"text\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-tooltip.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});
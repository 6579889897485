define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-selector-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>
    <a target="_blank" href={{i18n (concat @prefix ".link")}} rel="noopener noreferrer">
      {{i18n (concat @prefix ".link_text")}}
    </a>
  </li>
  */
  {
    "id": "12+wZxei",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[28,[37,2],[[28,[37,3],[[30,1],\".link\"],null]],null]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,3],[[30,1],\".link_text\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@prefix\"],false,[\"li\",\"a\",\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-selector-dropdown.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/pathway-playlists", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- Playlists: --}}
  
  {{!-- Machine Learning --}}
  {{#stem-category-insert categories="175|234" class="pathway-playlist-button"}}
    <a data-fancybox href="https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SbtYQJeY7TfzIwuFiD33EY0" class="btn-default btn btn-icon-text">
      {{d-icon "play"}}
      {{i18n "components.pathway_playlists.title"}}
    </a>
  {{/stem-category-insert}}
  
  {{!-- Bioinformatics --}}
  {{#stem-category-insert categories="170|233" class="pathway-playlist-button"}}
    <a data-fancybox href="https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SZqqYjfIvwcjGSwEEsJFqCM" class="btn-default btn btn-icon-text">
      {{d-icon "play"}}
      {{i18n "components.pathway_playlists.title"}}
    </a>
  {{/stem-category-insert}}
  
  {{!-- Full Stack --}}
  {{#stem-category-insert categories="169|235" class="pathway-playlist-button"}}
    <a data-fancybox href="https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5Sa3GOu0x8bHA4HK33cGyx-5" class="btn-default btn btn-icon-text">
      {{d-icon "play"}}
      {{i18n "components.pathway_playlists.title"}}
    </a>
  {{/stem-category-insert}}
  
  {{!-- UX/UI --}}
  {{#stem-category-insert categories="177|" class="pathway-playlist-button"}}
    <a data-fancybox href="https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SatUxKrDnyDvJW9RDg-4Eh2" class="btn-default btn btn-icon-text">
      {{d-icon "play"}}
      {{i18n "components.pathway_playlists.title"}}
    </a>
  {{/stem-category-insert}}
  */
  {
    "id": "gTtl6J02",
    "block": "[[[1,\"\\n\"],[6,[39,0],null,[[\"categories\",\"class\"],[\"175|234\",\"pathway-playlist-button\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[14,\"data-fancybox\",\"\"],[14,6,\"https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SbtYQJeY7TfzIwuFiD33EY0\"],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"play\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"components.pathway_playlists.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"categories\",\"class\"],[\"170|233\",\"pathway-playlist-button\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[14,\"data-fancybox\",\"\"],[14,6,\"https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SZqqYjfIvwcjGSwEEsJFqCM\"],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"play\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"components.pathway_playlists.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"categories\",\"class\"],[\"169|235\",\"pathway-playlist-button\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[14,\"data-fancybox\",\"\"],[14,6,\"https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5Sa3GOu0x8bHA4HK33cGyx-5\"],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"play\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"components.pathway_playlists.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"categories\",\"class\"],[\"177|\",\"pathway-playlist-button\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[14,\"data-fancybox\",\"\"],[14,6,\"https://www.youtube.com/embed/videoseries?list=PLXXLe1lWv5SatUxKrDnyDvJW9RDg-4Eh2\"],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"play\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"components.pathway_playlists.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"stem-category-insert\",\"a\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/pathway-playlists.hbs",
    "isStrictMode": false
  });
});
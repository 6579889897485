define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/home-features-set", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Virtual Internship Card }}
  {{#each featureSet as |feature|}}
    <div class="spages-card spages-card-effects">
      <div class="spages-card-image">
        {{stem-image imgPath=feature.imgPath altText=feature.altText}}
      </div>
      <div class="spages-card-body">
        <h6 class="spages-card-title">
          <a target="_blank" href={{i18n feature.url}} rel="noopener noreferrer">
            {{i18n feature.title}}
          </a>
        </h6>
        <p class="spages-card-content">
          {{i18n feature.content}}
        </p>
      </div>
    </div>
  {{/each}}
  */
  {
    "id": "j6QZDf6m",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"featureSet\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"spages-card spages-card-effects\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"spages-card-image\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"imgPath\",\"altText\"],[[30,1,[\"imgPath\"]],[30,1,[\"altText\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"spages-card-body\"],[12],[1,\"\\n      \"],[10,\"h6\"],[14,0,\"spages-card-title\"],[12],[1,\"\\n        \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[28,[37,6],[[30,1,[\"url\"]]],null]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[1,[28,[35,6],[[30,1,[\"title\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"spages-card-content\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[[30,1,[\"content\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `featureSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/home-features-set.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.featureSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"feature\"],false,[\"each\",\"-track-array\",\"div\",\"stem-image\",\"h6\",\"a\",\"i18n\",\"p\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/home-features-set.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-resources", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each resourceSet as |resource|}}
    <div class="guide-resources-cards-item">
      <ul>
        <li>
          <h5>
            {{#if resource.special}}
              <svg class="fab d-icon d-icon-discourse svg-icon svg-node">
                {{html-safe resource.logo}}
              </svg>
            {{else}}
              {{d-icon resource.logo}}
            {{/if}}
            {{i18n resource.title}}
          </h5>
        </li>
        {{guide-resource-items prefix=resource.list}}
      </ul>
    </div>
  {{/each}}
  */
  {
    "id": "D/wCFKE5",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"resourceSet\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"guide-resources-cards-item\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,\"h5\"],[12],[1,\"\\n\"],[41,[30,1,[\"special\"]],[[[1,\"            \"],[10,\"svg\"],[14,0,\"fab d-icon d-icon-discourse svg-icon svg-node\"],[12],[1,\"\\n              \"],[1,[28,[35,8],[[30,1,[\"logo\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,9],[[30,1,[\"logo\"]]],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[1,[28,[35,10],[[30,1,[\"title\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,11],null,[[\"prefix\"],[[30,1,[\"list\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `resourceSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-resources.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.resourceSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"resource\"],false,[\"each\",\"-track-array\",\"div\",\"ul\",\"li\",\"h5\",\"if\",\"svg\",\"html-safe\",\"d-icon\",\"i18n\",\"guide-resource-items\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-resources.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
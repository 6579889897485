define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-notification", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-icon icon}}
  <p>
    {{html-safe (i18n message)}}
  </p>
  */
  {
    "id": "qL4R9ZdL",
    "block": "[[[1,[28,[35,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"],[10,2],[12],[1,\"\\n  \"],[1,[28,[35,2],[[28,[37,3],[[30,0,[\"message\"]]],null]],null]],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `icon` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-notification.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.icon}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `message` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-notification.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.message}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-icon\",\"p\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-notification.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});
define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/discovery-below/below-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <RouteControl @restrictToRoute="/">
    <OnlyGuest>
      <section class="work-ready-synonyms-mainpage">
        {{work-ready-synonyms
          prefix="components.work_ready_synonyms.search_results.result"
        }}
      </section>
  
      <StemHowItWorks />
    </OnlyGuest>
  </RouteControl>
  */
  {
    "id": "IVXORvJC",
    "block": "[[[8,[39,0],null,[[\"@restrictToRoute\"],[\"/\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"work-ready-synonyms-mainpage\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"prefix\"],[\"components.work_ready_synonyms.search_results.result\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,4],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"route-control\",\"only-guest\",\"section\",\"work-ready-synonyms\",\"stem-how-it-works\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/discovery-below/below-header.hbs",
    "isStrictMode": false
  });
});
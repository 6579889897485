define("discourse/plugins/stemaway-custom-pages/discourse/templates/partners/examples", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="stemaway-pages-ad-center-examples">
    <header>
      <h1>
        {{i18n "pages.adExamples.heading.title"}}
      </h1>
      <p>
        {{i18n "pages.adExamples.heading.description"}}
      </p>
    </header>
    <section class="ad-center-examples">
      <a
        href="https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/a/a1c243683de22b0ac2c02f33d6894a806321da04.png"
        data-fancybox
        data-caption="Example of Bridge 12 Static Tagline Ad in the STEM-Away&reg; Forum"
      >
        {{stem-image
          path="https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/d/dc3919f70aa27a1d216021c546e16021e827e712.png"
          altText=""
        }}
      </a>
      <a
        href="https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/0/01c0e7c9b8b085f3c28b310b74f4a9bd12fa38bd.png"
        data-fancybox
        data-caption="Example of NYU Static Background Ad in the STEM-Away&reg; Forum"
      >
        {{stem-image
          path="https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/0/0e770b8ee17a164d916f7f2fc92fbd00ab0ba342.png"
          altText=""
        }}
      </a>
    </section>
  </div>
  */
  {
    "id": "Dq74z9pv",
    "block": "[[[10,0],[14,1,\"stemaway-pages-ad-center-examples\"],[12],[1,\"\\n  \"],[10,\"header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"pages.adExamples.heading.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"pages.adExamples.heading.description\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"ad-center-examples\"],[12],[1,\"\\n    \"],[10,3],[14,6,\"https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/a/a1c243683de22b0ac2c02f33d6894a806321da04.png\"],[14,\"data-fancybox\",\"\"],[14,\"data-caption\",\"Example of Bridge 12 Static Tagline Ad in the STEM-Away® Forum\"],[12],[1,\"\\n      \"],[1,[28,[35,7],null,[[\"path\",\"altText\"],[\"https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/d/dc3919f70aa27a1d216021c546e16021e827e712.png\",\"\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[14,6,\"https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/0/01c0e7c9b8b085f3c28b310b74f4a9bd12fa38bd.png\"],[14,\"data-fancybox\",\"\"],[14,\"data-caption\",\"Example of NYU Static Background Ad in the STEM-Away® Forum\"],[12],[1,\"\\n      \"],[1,[28,[35,7],null,[[\"path\",\"altText\"],[\"https://stemaway-updivision-urlsigner.storage.googleapis.com/original/2X/0/0e770b8ee17a164d916f7f2fc92fbd00ab0ba342.png\",\"\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"header\",\"h1\",\"i18n\",\"p\",\"section\",\"a\",\"stem-image\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/partners/examples.hbs",
    "isStrictMode": false
  });
});